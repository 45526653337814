import * as React from 'react';
import { useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import { PostCard } from '../components/postCard';
import { logAnalysis } from '../../api/analytic';

const IndexPage = ({ data }) => {
  useEffect(() => {
    logAnalysis('home');
  }, []);

  return (
    <Layout>
      <div>
        <div className={'text-2xl font-medium'}>
          This is my outlet for personal thoughts and a record keeping place for
          things I found useful in software development for me and for others
        </div>

        <div className={'flex justify-between place-items-end'}>
          <div className={'text-8xl mt-14 font-bold'}>Hậu Trần</div>
          <Link to='/about' className={'font-bold text-2xl italic'}>
            /about
          </Link>
        </div>

        <div className={'mt-24'}>
          {data.allMdx.nodes.map((node, index) => (
            <PostCard
              postTitle={node.frontmatter.title}
              createdAt={node.frontmatter.date}
              postType={node.frontmatter.type}
              slug={node.slug}
              excerpt={node.frontmatter.excerpt}
              key={node.id}
              isOdd={index % 2}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          type
          excerpt
        }
        id
        slug
      }
    }
  }
`;

export default IndexPage;
