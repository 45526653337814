import * as React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Link } from 'gatsby';
export const PostCard = ({
  isOdd,
  postTitle,
  createdAt,
  postType,
  slug,
  excerpt,
}) => {
  const mainDivAnimationControl = useAnimation();
  const excerptAnimationControl = useAnimation();

  const startAnimation = async () => {
    mainDivAnimationControl.start({
      height: '300px',
    });

    excerptAnimationControl.start({
      opacity: 1,
      display: 'inline-block',
    });
  };

  const removeAnimation = async () => {
    mainDivAnimationControl.start({
      height: '100%',
    });

    excerptAnimationControl.start({
      opacity: 0,
      display: 'none',
    });
  };

  return (
    <Link to={`/post/${slug}`}>
      <div
        className={`border-2 rounded-tl-xl rounded-br-xl border-black mb-14 p-6 relative post-card--hovered hover:bg-yellow-50
      ${isOdd ? 'bg-[#ffffff]' : 'bg-[#F1F1F1]'}`}
      >
        <div
          className={
            'absolute -right-[2px] -top-[34px] bg-black text-white p-1 w-[125px] text-center'
          }
        >
          {postType}
        </div>
        <div className='text-base italic absolute right-6'>{createdAt}</div>
        <h1 className='text-xl font-bold'>{postTitle.toUpperCase()}</h1>
        <div className='mt-2 text-base'>{excerpt}</div>
        <div className='text-right mt-2 underline underline-offset-1'>
          Read More
        </div>
      </div>
    </Link>
  );
};
